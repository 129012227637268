<template>
  <Popover :config="addActionPopoverConfig">
    <div contenteditable="false" v-if="addActionPopoverConfig.show" class="p-2 bg-white rounded add-action-container">
      <div v-show="!(isMobile || isDemo)" class="flex items-center p-2 rounded cursor-pointer group hover:bg-primary-200" @click="openPopover('File')">
        <div class="flex items-center justify-center mr-2 h-9 w-9">
          <BrandIcon class="h-7 text-primary-400 w-7 group-hover:text-red" />
        </div>

        <div>
          <div class="text-sm font-bold text-primary">Spext Doc</div>
          <div class="text-sm text-primary-800">
            Add a file from your drive
          </div>
        </div>
      </div>

      <div class="mx-1 my-2 border-t border-primary-200"/>

      <div v-show="!isMobile" class="flex items-center p-2 rounded cursor-pointer group hover:bg-primary-200" @click="openPopover('Sound')">
        <MusicIcon class="mr-2 h-9 text-primary-400 w-9 group-hover:text-primary" />

        <div>
          <div class="text-sm font-bold text-primary">Sound</div>
          <div class="text-sm text-primary-800">Add music or a sound effect</div>
        </div>
      </div>
    </div>
  </Popover>
</template>
<script>
import store from '@/services/store'
import BrandIcon from '@/components/base/icons/Brand.vue'
import MusicIcon from '@/components/base/icons/MusicFile.vue'

export default {
  name: 'AddActionPopover',
  components: {
    BrandIcon,
    MusicIcon,
    Popover: () => import('./Popover/Popover')
  },
  computed: {
    addActionPopoverConfig () {
      return store.state.editor.addActionPopoverConfig
    },
    addFilePopoverConfig () {
      return store.state.modal.addFilePopoverConfig
    },
    isMobile () {
      return store.state.app.isMobile
    },
    isDemo () {
      return store.state.editor.isDemo
    }
  },
  methods: {
    closeActionPopover () {
      store.state.editor.addActionPopoverConfig.show = false
    },
    openPopover (type) {
      if (type === 'File') window.analytics.track('editor_addSpextDoc')
      else window.analytics.track('editor_addMusicFiles')

      this.closeActionPopover()

      store.dispatch('dialogs/openModal', { name: 'AddFile',
        props: {
          soundMode: type === 'Sound'
        } })
    }
  }
}
</script>
<style lang="scss" scoped>
.add-action-container {
  width: max-content;
  position: absolute;
  box-shadow: 0px 0px 4.14286px rgba(0, 2, 40, 0.1);
}
</style>
