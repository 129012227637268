import { render, staticRenderFns } from "./MusicFile.vue?vue&type=template&id=436bf597&scoped=true&"
import script from "./MusicFile.vue?vue&type=script&lang=js&"
export * from "./MusicFile.vue?vue&type=script&lang=js&"
import style0 from "./MusicFile.vue?vue&type=style&index=0&id=436bf597&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "436bf597",
  null
  
)

export default component.exports